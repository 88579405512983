// @ts-nocheck
import { GeneralApiProblem } from './api-problem';
import {
  Pagination,
  DropStationKindType,
  DropStationType,
  StationType,
  DropCodeType,
  DropInType,
  OverviewType,
  SorterType,
  ClientListType,
  JerryCanRequestType,
  ReceiptType,
  UcoCityType,
  UcoDistrictType,
  UcoPickupRequestType,
  UcoTextSettingType,
  BannerType,
  AccountListType,
  BalanceByMethodType,
  WithdrawListType,
  WithdrawMethod,
  WithdrawMethodHistoryType,
  DropStationDetailType,
} from 'types';

/**
 * Storage Key
 */
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const PROFILE_ID_KEY = 'id';
export const ROLE_ID_KEY = 'roleId';

/**
 * API Definition
 */
const AUTH_SERVICE = process.env.REACT_APP_AUTH_SERVICE;
const DROP_STATION_SERVICE = process.env.REACT_APP_DROP_STATION_SERVICE;
const DROP_IN_SERVICE = process.env.REACT_APP_DROP_IN_SERVICE;
const REKOPOINT_SERVICE = process.env.REACT_APP_REKO_POINT_SERVICE;
const UCO_PICKUP_PORT = process.env.REACT_APP_UCO_PICKUP;
const UCO_ROUTINE_PICKUP_PORT = process.env.REACT_APP_UCO_PICKUP_ROUTINE;
const UCO_JERRY_CAN_PORT = process.env.REACT_APP_UCO_JERRY_CAN;
const UCO_SETTING_PORT = process.env.REACT_APP_UCO_SETTING;
const FRAUD_SERVICE = process.env.REACT_APP_FRAUD_SERVICE;

// Port
export const AUTH_PORT = AUTH_SERVICE + '/admin';
export const DROP_IN_PORT = DROP_IN_SERVICE + '/admin';
export const DROP_STATION_PORT = DROP_STATION_SERVICE + '/admin';
export const REKOPOINT_PORT = REKOPOINT_SERVICE + '/admin';
export const FRAUD_PORT = FRAUD_SERVICE + '/admin';

// Auth & User
export const LOGIN = AUTH_SERVICE + '/auth/login-admin';
export const USER_DETAIL = AUTH_PORT + '/user/';
export const REFRESH_TOKEN_ADMIN = AUTH_SERVICE + '/auth/refresh-token-admin';
export const CHECK_PASSWORD = AUTH_PORT + '/verify-password';

// Account Management
export const ACCOUNT_LIST = AUTH_PORT + '/user-admin';
export const ACCOUNT_LIST_BY_STATUS = AUTH_PORT + '/user-admin/by-status';
export const GET_ACCOUNT_DETAIL = AUTH_PORT + '/user-admin/';
export const EDIT_ACCOUNT_DETAIL = AUTH_PORT + '/user-admin/';
export const CREATE_ACCOUNT = AUTH_PORT + '/user-admin';
export const GET_ROLE = AUTH_PORT + '/role';
export const DELETE_ACCOUNT = AUTH_PORT + '/user-admin/';

// Drop Station
export const DROP_STATION = DROP_STATION_PORT + '/drop-station';
export const DROP_STATION_DELETE = DROP_STATION_PORT + '/drop-station/';
export const DROP_STATION_UPDATE_STATUS =
  DROP_STATION_PORT + '/drop-station/update-status/';
export const PROVINCE = DROP_STATION_PORT + '/province/search';
export const REGENCY = DROP_STATION_PORT + '/regency/search';
export const DISTRICT = DROP_STATION_PORT + '/district/search';
export const VILLAGE = DROP_STATION_PORT + '/village/search';
export const DROP_STATION_NEW = DROP_IN_PORT + '/drop-transaction/drop-station';
export const DROP_STATION_TYPE = DROP_STATION_PORT + '/drop-station/type';
export const EPR_PROGRAM_TYPE = DROP_STATION_PORT + '/epr-partners';
export const DROP_STATION_MARKERS = DROP_STATION_PORT + '/drop-station/marker';

// Drop IN
export const DROP_IN = DROP_IN_PORT + '/drop-transaction';
export const DROP_CODE = DROP_IN + '/drop-code';
export const DROP_IN_DETAIL = DROP_IN + '/detail';
export const DROP_IN_SORT = DROP_IN + '/sort';
export const DROP_IN_WASTE = DROP_IN + '/waste';
export const DROP_IN_RECEIVE = DROP_IN + '/receive';
export const DROP_IN_WITHOUT_CODE = DROP_IN + '/generate-code';
export const DROP_IN_SORTER = DROP_IN + '/sorter';
export const DROP_IN_SORT_FORM = DROP_IN + '/sort-form';
export const DROP_IN_SUBMIT_EPR = DROP_IN + '/sort-epr';
export const DROP_IN_RECEIVE_FORM = DROP_IN + '/receive-form';
export const DROP_WASTE = DROP_IN + '/drop-waste';

// Rebox
export const REBOX = DROP_STATION_PORT + '/rebox';
export const REBOX_UPDATE = DROP_STATION_PORT + '/rebox/update';

// Product
export const PRODUCT = DROP_STATION_PORT + '/rebox/product';
export const PRODUCT_UPDATE = DROP_STATION_PORT + '/rebox/product/update';

// Withdraw
export const WITHDRAW_METHOD = REKOPOINT_PORT + '/withdraw-method';
export const WITHDRAW_LIST = REKOPOINT_PORT + '/withdraw';
export const WITHDRAW_BALANCE_BY_METHOD =
  REKOPOINT_PORT + '/withdraw/balance-by-method';
export const WITHDRAW_DELETE = REKOPOINT_PORT + '/withdraw/';
export const WITHDRAW_EDIT = REKOPOINT_PORT + '/withdraw/';
export const WITHDRAW_METHOD_HISTORY =
  REKOPOINT_PORT + '/withdraw/history-by-method/';
export const UPDATE_BLU_HISTORY = REKOPOINT_PORT + '/update-blu-history';
export const WITHDRAW_METHOD_TOP_UP = REKOPOINT_PORT + '/rekopoints-balance';
export const WITHDRAW_METHOD_EDIT_TOP_UP =
  REKOPOINT_PORT + '/rekopoints-balance/';
export const DOWNLOAD_BANK_STATEMENT =
  REKOPOINT_PORT + '/withdraw/excel/bank-statement/';
export const DOWNLOAD_TRANSACTION_HISTORY =
  REKOPOINT_PORT + '/withdraw/excel/transaction-history/';
export const REFRESH_BALANCE = REKOPOINT_PORT + '/withdraw/refresh-balance';
export const TRANSFER_BALANCE = REKOPOINT_PORT + '/withdraw/transfer';
export const TRANSFER_BALANCE_ASTRA =
  REKOPOINT_PORT + '/withdraw/transfer/astrapay';
export const EDIT_WITHDRAW_STATUS_BY_IDS =
  REKOPOINT_PORT + '/withdraw/update-status-by-ids';
export const WITHDRAW_TAB_COUNT = REKOPOINT_PORT + '/withdraw/count-status';

// Threshold Setting
export const THRESHOLD_SETTING_LIST = FRAUD_PORT + '/threshold';
export const THRESHOLD_SETTING_TYPE_LIST = THRESHOLD_SETTING_LIST + '/types';

// Fraud Management
export const FRAUD_MANAGEMENT_LIST = REKOPOINT_PORT + '/fraud';
export const FRAUD_REASON_LIST = REKOPOINT_PORT + '/fraud/reason';
export const FRAUD_MANAGEMENT_DOWNLOAD_TRANSACTION =
  REKOPOINT_PORT + '/fraud/excel/';

// Voucher
export const VOUCHER_LIST = DROP_IN + '/voucher';

// UCO Pick Up
export const PICKUP_REQUEST = UCO_PICKUP_PORT + '';
export const PICKUP_REQUEST_BY_STATUS = UCO_PICKUP_PORT + '/by-status';
export const PICKUP_SEARCH = UCO_PICKUP_PORT + '/search';
export const PICKUP_REQUEST_DETAIL = UCO_PICKUP_PORT + '/';
export const PICKUP_REQUEST_TRANSFER = UCO_PICKUP_PORT + '/transfer/';
export const PICKUP_REQUEST_DOWNLOAD_DATA =
  UCO_PICKUP_PORT + '/not-picked-up-report';
export const PICKUP_REQUEST_DOWNLOAD_RECEIPT = UCO_PICKUP_PORT + '/pdf-payment';
export const PICKUP_REQUEST_EDIT_CS_CONFIRMATION =
  UCO_PICKUP_PORT + '/cs-confirmation';
export const PICKUP_REQUEST_DELETE = UCO_PICKUP_PORT + '';

// UCO Jerry Can
export const JERRY_CAN_REQUEST = UCO_JERRY_CAN_PORT + '';
export const JERRY_CAN_SEARCH = UCO_JERRY_CAN_PORT + '/search';
export const JERRY_CAN_REQUEST_BY_STATUS = UCO_JERRY_CAN_PORT + '/by-status';
export const JERRY_CAN_DETAIL = UCO_JERRY_CAN_PORT + '/';
export const JERRY_CAN_DEPOSIT = UCO_JERRY_CAN_PORT + '/deposit/';
export const JERRY_CAN_REQUEST_DATA =
  UCO_JERRY_CAN_PORT + '/not-picked-up-report';
export const JERRY_CAN_REQUEST_DEPOSIT =
  UCO_JERRY_CAN_PORT + '/pdf-payment-deposit';
export const JERRY_CAN_REQUEST_REFUND =
  UCO_JERRY_CAN_PORT + '/pdf-refund-deposit';

//UCO Client
export const UCO_CLIENT_LIST = UCO_PICKUP_PORT + '/client';
export const UCO_CLIENT_DETAIL = UCO_PICKUP_PORT + '/client';
export const UCO_ADD_CLIENT = UCO_PICKUP_PORT + '/client';
export const UCO_EDIT_CLIENT = UCO_PICKUP_PORT + '/client';
export const UCO_DELETE_CLIENT = UCO_PICKUP_PORT + '/client';

// UCO Routine Pick Up
export const ROUTINE_PICKUP_REQUEST = UCO_ROUTINE_PICKUP_PORT + '';
export const ROUTINE_PICKUP_REQUEST_BY_STATUS =
  UCO_ROUTINE_PICKUP_PORT + '/by-status';
export const ROUTINE_PICKUP_SEARCH = UCO_ROUTINE_PICKUP_PORT + '/search';
export const ROUTINE_PICKUP_REQUEST_DETAIL = UCO_ROUTINE_PICKUP_PORT + '/';
export const ROUTINE_PICKUP_REQUEST_TRANSFER =
  UCO_ROUTINE_PICKUP_PORT + '/transfer/';
export const ROUTINE_PICKUP_REQUEST_DOWNLOAD_DATA =
  UCO_ROUTINE_PICKUP_PORT + '/not-picked-up-report';
export const ROUTINE_PICKUP_REQUEST_DOWNLOAD_RECEIPT =
  UCO_ROUTINE_PICKUP_PORT + '/pdf-payment';
export const ROUTINE_PICKUP_REQUEST_EDIT_CS_CONFIRMATION =
  UCO_ROUTINE_PICKUP_PORT + '/cs-confirmation';
export const ROUTINE_PICKUP_REQUEST_DELETE = UCO_ROUTINE_PICKUP_PORT + '';

// Banner
export const BANNERS = DROP_IN_PORT + '/banner';
export const BANNER_DELETE = DROP_IN_PORT + '/banner/';

// UCO SETTING
export const UCO_SETTING_HOME = UCO_SETTING_PORT + '/home';
export const UCO_SETTING_JERRY_CAN_PRICE =
  UCO_SETTING_PORT + '/jerry-can/price';
export const UCO_SETTING_PICKUP = UCO_SETTING_PORT + '/pick-up';
export const UCO_SETTING_EDIT_TEXT = UCO_SETTING_PORT + '';
export const UCO_SETTING_CITY = UCO_SETTING_PORT + '/city';
export const UCO_SETTING_MASTER_CITY = UCO_SETTING_PORT + '/master-city';
export const UCO_SETTING_DISTRICT = UCO_SETTING_PORT + '/district';
export const UCO_SETTING_MASTER_DISTRICT =
  UCO_SETTING_PORT + '/master-district';
export const UCO_SETTING_CONTACT = UCO_SETTING_PORT + '/contact';

// Other.. API
// const OTHER_PORT = process.env.REACT_APP_OTHER;
// export const OTHER_URL = OTHER_PORT + '/other';

export interface GeneralDataResponse<T = any> {
  pagination?: any;
  data: T;
  errors: any;
  message: string | any;
  status: boolean | any;
}

export type GeneralApiResult =
  | { kind: 'ok'; data: GeneralDataResponse }
  | GeneralApiProblem;

export type LoginResult =
  | { kind: 'ok'; data: GeneralDataResponse }
  | GeneralApiProblem;

export interface DropStationResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    content: DropStationType[];
  };
}

export interface DropStationTypeResponse extends GeneralDataResponse {
  data: DropStationKindType[];
}

export interface BannerResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    content: BannerType[];
  };
}

export interface DropInOverviewResponse extends GeneralDataResponse {
  data: OverviewType;
}

export interface DropInResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    content: DropInType[];
  };
}

export interface DropInDetailResponse extends GeneralDataResponse {
  data: DropInType;
}

export interface DropInSorterResponse extends GeneralDataResponse {
  data: SorterType[];
}

export interface DropInWithoutCode extends GeneralDataResponse {
  dropCode: string;
}

export interface PickupRequestResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    content: UcoPickupRequestType[];
  };
}

export interface PickupDataResponse extends GeneralDataResponse {
  data: {
    url: string;
  };
}

export interface PickupPaymentReceiptResponse extends GeneralDataResponse {
  data: ReceiptType[];
}

export interface JerryCanRequestResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    content: JerryCanRequestType[];
  };
}

export interface UcoTextSettingResponse extends GeneralDataResponse {
  data: UcoTextSettingType[];
}

export interface UcoJerryCanPriceSettingResponse extends GeneralDataResponse {
  data: UcoTextSettingType;
}

export interface UcoCityResponse extends GeneralDataResponse {
  data: UcoCityType[];
}

export interface UcoDistrictResponse extends GeneralDataResponse {
  data: UcoDistrictType[];
}

export interface UcoClientListResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    data: ClientListType[];
  };
}

// Account management
export interface AccountListResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    content: AccountListType[];
  };
}

// Withdraw
export interface WithdrawMethodResponse extends GeneralDataResponse {
  data: WithdrawMethod[];
}
export interface WithdrawListResponse extends GeneralDataResponse {
  data: WithdrawListType[];
}

export interface WithdrawBalanceByMethodResponse extends GeneralDataResponse {
  data: BalanceByMethodType[];
}

export interface WithdrawMethodHistoryResponse extends GeneralDataResponse {
  data: WithdrawMethodHistoryType[];
}

export type StationResult =
  | { kind: 'ok'; data: StationType[] }
  | GeneralApiProblem;

export type DropStationResult =
  | { kind: 'ok'; data: DropStationResponse }
  | GeneralApiProblem;

export type DropStationTypeResult =
  | { kind: 'ok'; data: DropStationTypeResponse }
  | GeneralApiProblem;

export type DropInResult =
  | { kind: 'ok'; data: DropInOverviewResponse }
  | GeneralApiProblem;

export type DropCodeResult =
  | { kind: 'ok'; data: DropCodeType[] }
  | GeneralApiProblem;

export type DropInDetailResult =
  | { kind: 'ok'; data: DropInDetailResponse }
  | GeneralApiProblem;

export type DropInSorterResult =
  | { kind: 'ok'; data: DropInSorterResponse }
  | GeneralApiProblem;

export type DropInWithoutCodeResult =
  | { kind: 'ok'; data: DropInWithoutCode }
  | GeneralApiProblem;

export type DropStationDetailResult =
  | { kind: 'ok'; data: DropStationDetailType }
  | GeneralApiProblem;

export type DropStationBooleanResult =
  | { kind: 'ok'; data: boolean }
  | GeneralApiProblem;

export type PickupRequestResult =
  | { kind: 'ok'; data: PickupRequestResponse }
  | GeneralApiProblem;

export type PickupDataResult =
  | { kind: 'ok'; data: PickupDataResponse }
  | GeneralApiProblem;

export type PickupPaymentReceiptResult =
  | { kind: 'ok'; data: PickupPaymentReceiptResponse }
  | GeneralApiProblem;

export type JerryCanRequestResult =
  | { kind: 'ok'; data: JerryCanRequestResponse }
  | GeneralApiProblem;

export type GeneralSubmitResult =
  | { kind: 'ok'; data: GeneralDataResponse }
  | GeneralApiProblem;

export type BannerResult =
  | { kind: 'ok'; data: BannerResponse }
  | GeneralApiProblem;

export type BannerBooleanResult =
  | { kind: 'ok'; data: boolean }
  | GeneralApiProblem;

export type UcoTextSettingResult =
  | { kind: 'ok'; data: UcoTextSettingResponse }
  | GeneralApiProblem;

export type UcoJerryCanPriceSettingResult =
  | { kind: 'ok'; data: UcoJerryCanPriceSettingResponse }
  | GeneralApiProblem;

export type UcoCityResult =
  | { kind: 'ok'; data: UcoCityResponse }
  | GeneralApiProblem;

export type UcoDistrictResult =
  | { kind: 'ok'; data: UcoDistrictResponse }
  | GeneralApiProblem;

export type UcoClientListResult =
  | { kind: 'ok'; data: UcoClientListResponse }
  | GeneralApiProblem;

export type AccountListResult =
  | { kind: 'ok'; data: AccountListResponse }
  | GeneralApiProblem;

export type WithdrawMethodResult =
  | { kind: 'ok'; data: WithdrawMethodResponse }
  | GeneralApiProblem;

export type WithdrawListResult =
  | { kind: 'ok'; data: WithdrawListResponse }
  | GeneralApiProblem;

export type WithdrawBalanceByMethodResult =
  | { kind: 'ok'; data: WithdrawBalanceByMethodResponse }
  | GeneralApiProblem;

export type WithdrawMethodHistoryResult =
  | { kind: 'ok'; data: WithdrawMethodHistoryResponse }
  | GeneralApiProblem;

export type GenericApiResult<T = any> =
  | { kind: 'ok'; data: GeneralDataResponse<T> }
  | GeneralApiProblem;
