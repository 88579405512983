import React from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { IReactSelectProps } from 'components/ReactSelect/react-select.props';

const ERROR_FORM =
  'w-full border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm';

const BASE_FORM = 'w-full';

export const ReactSelect = ({
  label,
  withLabel = true,
  onSelect = () => null,
  onInputChange = () => null,
  value,
  isError = false,
  topLine = false,
  focus = false,
  disable = false,
  errorMessage = '',
  isBlockWith = false,
  placeholder,
  loadOptions = () => {},
  isAsync,
  // @ts-ignore
  options,
  isClearable = true,
  isMulti = false,
  onMenuScrollToBottom,
  requiredLabel = false,
}: IReactSelectProps) => {
  if (isAsync) {
    return (
      <div
        className={
          topLine
            ? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'
            : 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start'
        }
      >
        {withLabel && (
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            {label}
            {requiredLabel && <label className="text-crimsonRed-500">*</label>}
          </label>
        )}
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex rounded-md shadow-sm">
            <AsyncSelect
              isDisabled={disable}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              autoFocus={focus}
              className={isError ? ERROR_FORM : BASE_FORM}
              defaultValue={value}
              cacheOptions
              placeholder={placeholder}
              loadOptions={loadOptions}
              defaultOptions
              onInputChange={(newValue: string) => {
                onInputChange(newValue);
              }}
              onChange={newValue => {
                onSelect(newValue);
              }}
              isMulti={isMulti}
              onMenuScrollToBottom={onMenuScrollToBottom}
            />
          </div>
          {isError === true ? (
            <div className="mt-2 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <p className="ml-2 text-sm text-red-600" id="email-error">
                {errorMessage}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        topLine
          ? 'block sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 w-full'
          : 'block sm:gap-4 sm:items-start w-full'
      }
    >
      {withLabel && (
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
        </label>
      )}
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="flex rounded-md shadow-sm">
          <Select
            autoFocus={focus}
            isDisabled={disable}
            className={isError ? ERROR_FORM : BASE_FORM}
            value={value}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            options={options}
            isClearable={isClearable}
            isSearchable={true}
            placeholder={placeholder}
            onInputChange={(newValue: string) => {
              onInputChange(newValue);
            }}
            onChange={newValue => {
              onSelect(newValue);
            }}
            isMulti={isMulti}
            onMenuScrollToBottom={onMenuScrollToBottom}
          />
        </div>
        {isError === true ? (
          <div className="mt-2 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <p className="ml-2 text-sm text-red-600" id="email-error">
              {errorMessage}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
