import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.dropStation || initialState;

export const selectDropStation = createSelector([selectSlice], state => state);

export const selectDropStationList = createSelector(
  [selectSlice],
  state => state.list,
);

export const selectDropStationLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectDropStationError = createSelector(
  [selectSlice],
  state => state.error,
);

export const selectDropStationPaginationParams = createSelector(
  [selectSlice],
  state => state.paginationParams,
);

export const selectDropStationPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);

export const selectDropStationId = createSelector(
  [selectSlice],
  state => state.dropStationId,
);
export const selectDropStationDetail = createSelector(
  [selectSlice],
  state => state.data,
);
export const selectDropStationType = createSelector(
  [selectSlice],
  state => state.dropStationType,
);

export const selectDropStationMessage = createSelector(
  [selectSlice],
  state => state.message,
);

export const selectSubmitSuccess = createSelector(
  [selectSlice],
  state => state.submitSuccess,
);

export const selectDeleteSuccess = createSelector(
  [selectSlice],
  state => state.deleteSuccess,
);

export const selectDropStationForm = createSelector(
  [selectSlice],
  state => state.dropStationForm,
);

export const selectStationList = createSelector(
  [selectSlice],
  state => state.stations,
);

export const selectDropStationEprList = createSelector(
  [selectSlice],
  state => state.eprProgramType,
);

export const selectDropStationMarkers = createSelector(
  [selectSlice],
  state => state.dropStationMarkers,
);

export const selectDropWaste = createSelector(
  [selectSlice],
  state => state.dropWaste,
);
