import { DayType, DropStationPromptType, SelectorOptionType } from 'types';
import * as Yup from 'yup';

export const ADD_DROP_POINT_BUTTON: string = 'Drop Station Baru';
export const INDEX_EDIT: string = 'Edit';
export const INDEX_DELETE: string = 'Delete';
export const DROP_STATION_ACTIVE: string = 'Active';
export const DROP_STATION_IN_ACTIVE: string = 'InActive';
export const DROP_STATION_FILTER: string = 'Sort by type';
export const LIST_TABLE = [
  {
    title: 'Nama',
    style:
      'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
  },
  {
    title: 'Jenis',
    style: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
  },
  {
    title: 'Status',
    style: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
  },
  {
    title: 'Alamat',
    style: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
  },
  {
    title: '',
    style: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
  },
];

export const DROP_POINT_DROP_BOX_TYPE: SelectorOptionType = {
  label: 'Drop Box',
  value: 1,
};
export const DROP_POINT_REGULAR_TYPE: SelectorOptionType = {
  label: 'Waste Station Reguler',
  value: 2,
};
export const DROP_POINT_CORPORATION_TYPE: SelectorOptionType = {
  label: 'Waste Station Korporasi (with key access)',
  value: 3,
};
export const DROP_POINT_COLLABORATION_TYPE: SelectorOptionType = {
  label: 'Waste Station Kolaborasi (with webview)',
  value: 4,
};
export const DROP_POINT_TYPE: SelectorOptionType[] = [
  DROP_POINT_DROP_BOX_TYPE,
  DROP_POINT_REGULAR_TYPE,
  DROP_POINT_CORPORATION_TYPE,
  DROP_POINT_COLLABORATION_TYPE,
];

export const DROP_POINT_FORM_PHOTO_LABEL: string = 'Foto Drop Point';
export const UPLOAD_IMAGE: string = 'Upload a file';
export const OVERWRITE_IMAGE: string = 'Overwrite the image';
export const UPLOAD_ICON: string = 'Upload icon';
export const OVERWRITE_ICON: string = 'Overwrite icon';
export const UPLOAD_IMAGE_DRAG_DROP: string = 'or drag and drop';
export const UPLOAD_IMAGE_TYPE_AND_SIZE: string = 'PNG, JPG, GIF up to 10MB';
export const DROP_POINT_FORM_NAME_LABEL: string = 'Nama Drop Point';
export const DROP_POINT_FORM_EPR_PROGRAM_LABEL: string = 'EPR Program';
export const DROP_POINT_FORM_NAME_PLACEHOLDER: string = 'Nama';
export const DROP_POINT_FORM_TYPE_LABEL: string = 'Jenis Drop Point';
export const DROP_POINT_FORM_ACTIVE_LABEL: string = 'Tanggal Aktif Drop Point';
export const DROP_POINT_FORM_COORDINATE_LABEL: string = 'Koordinat Drop Point';
export const DROP_POINT_FORM_LATITUDE_PLACEHOLDER: string = 'Latitude';
export const DROP_POINT_FORM_LONGITUDE_PLACEHOLDER: string = 'Longitude';
export const DROP_POINT_FORM_ADDRESS_LABEL: string = 'Alamat';
export const DROP_POINT_FORM_ADDRESS_PLACEHOLDER: string = 'Alamat';
export const DROP_POINT_FORM_RTRW_LABEL: string = 'RT/RW';
export const DROP_POINT_FORM_RT_PLACEHOLDER: string = 'RT';
export const DROP_POINT_FORM_RW_PLACEHOLDER: string = 'RW';
export const DROP_POINT_FORM_PROVINCE_LABEL: string = 'Provinsi';
export const DROP_POINT_FORM_PROVINCE_PLACEHOLDER: string = 'Provinsi';
export const DROP_POINT_FORM_REGENCY_LABEL: string = 'Kota/ Kabupaten';
export const DROP_POINT_FORM_REGENCY_PLACEHOLDER: string = 'Kota/ Kabupaten';
export const DROP_POINT_FORM_DISTRICT_LABEL: string = 'Kecamatan';
export const DROP_POINT_FORM_DISTRICT_PLACEHOLDER: string = 'Kecamatan';
export const DROP_POINT_FORM_VILLAGE_LABEL: string = 'Kelurahan';
export const DROP_POINT_FORM_VILLAGE_PLACEHOLDER: string = 'Kelurahan';
export const DROP_POINT_FORM_POSTAL_CODE_LABEL: string = 'Kodepos';
export const DROP_POINT_FORM_POSTAL_CODE_PLACEHOLDER: string = 'Kodepos';
export const DROP_POINT_FORM_BACK: string = 'Back';
export const DROP_POINT_FORM_CONFIRM: string = 'Confirm';
export const DROP_POINT_FORM_KEY_ACCESS_SWITCH: string =
  'Waste Station With Key Access';
export const DROP_POINT_FORM_EDIT_PASSWORD_SWITCH: string =
  'Enable Edit Password';
export const DAY_OPTION: DayType[] = [
  {
    label: 'Senin',
    value: 'Monday',
  },
  {
    label: 'Selasa',
    value: 'Tuesday',
  },
  {
    label: 'Rabu',
    value: 'Wednesday',
  },
  {
    label: 'Kamis',
    value: 'Thursday',
  },
  {
    label: 'Jumat',
    value: 'Friday',
  },
  {
    label: 'Sabtu',
    value: 'Saturday',
  },
  {
    label: 'Minggu',
    value: 'Sunday',
  },
];
export const DROP_POINT_FORM_OPERATIONAL_DAY_LABEL: string = 'Hari Operasional';
export const DROP_POINT_FORM_OPERATIONAL_TIME_LABEL: string = 'Jam operasional';
export const DROP_POINT_FORM_BREAK_TIME_LABEL: string = 'Jam istirahat';
export const TIME_RANGE_PLACEHOLDER: string = '_ _ . _ _ - _ _ . _ _';
export const DROP_POINT_FORM_KEY_ACCESS_LABEL: string =
  'Key Access Waste Station';
export const DROP_POINT_FORM_KEY_ACCESS_PLACEHOLDER: string =
  'Key Access Waste Station';
export const DROP_POINT_FORM_CONFIRM_KEY_ACCESS_LABEL: string =
  'Konfirmasi Key Access Waste Station';
export const DROP_POINT_FORM_CONFIRM_KEY_ACCESS_PLACEHOLDER: string =
  'Konfirmasi Key Access Waste Station';
export const DROP_POINT_FORM_CONFIRM_KEY_ACCESS_ERROR: string =
  'Konfirmasi Key Access Tidak Sesuai';
export const DROP_POINT_FORM_CORPORATE_DESCRIPTION_LABEL: string = 'Deskripsi';
export const DROP_POINT_FORM_CORPORATE_DESCRIPTION_PLACEHOLDER: string =
  'Deskripsi';
export const DROP_POINT_FORM_CORPORATE_DESCRIPTION_FOOTNOTE: string =
  'Teks singkat mengenai key accsess pada Drop Point (Contoh: Key Access khusus Karyawan Astra) ';
export const DROP_POINT_FORM_COLLABORATION_BANNER_LABEL: string =
  'Banner partner';
export const DROP_POINT_FORM_COLLABORATION_LOGO_LABEL: string = 'Logo partner';
export const DROP_POINT_PROMPT_DEFAULT: DropStationPromptType[] = [
  {
    prompt: '',
  },
];
export const DROP_POINT_FORM_COLLABORATION_PROMPT_LABEL: string = 'Prompt ';
export const DROP_POINT_FORM_COLLABORATION_PROMPT_PLACEHOLDER: string =
  'Prompt ';
export const CREATE_DROP_STATION_TITLE: string = `Create Drop Station`;
export const EDIT_DROP_STATION_TITLE: string = `Drop Station Edit`;

const MAX_LENGTH = 48;
const MAX_LENGTH_TITLE = 27;
const MAX_LENGTH_TEXT_AREA = 400;

export const CAMPAIGN_VALIDATION_SCHEMA = Yup.object().shape({
  isCampaign: Yup.boolean(),
  campaignName: Yup.string().when('isCampaign', {
    is: true,
    then: Yup.string().required('Nama campaign wajib diisi!'),
  }),
  campaignStartDate: Yup.date()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.date().required('Durasi campaign wajib diisi!'),
    }),
  campaignEndDate: Yup.date()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.date().required('Durasi campaign wajib diisi!'),
    }),
  selectedDropWaste: Yup.object()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.object().test(
        'is-filled',
        'Jenis komoditas campaign wajib diisi!',
        value => value && Object.keys(value).length > 0,
      ),
    }),
  campaignReward: Yup.number()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.number().required('Hadiah campaign wajib diisi!'),
    }),
  selectedMarker: Yup.object()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.object().test(
        'is-filled',
        'Waste station marker wajib diisi!',
        value => value && Object.keys(value).length > 0,
      ),
    }),
  wasteStationCampaignRewardInformation: Yup.string()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.string()
        .required('Informasi reward wajib diisi!')
        .test(
          'max-length',
          ({ value }) =>
            `Informasi reward melebihi batas maksimum! (${value?.length}/${MAX_LENGTH})`,
          value => !value || value.length <= MAX_LENGTH,
        ),
    }),
  wasteTypeCampaignRewardInformation: Yup.string()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.string()
        .required('Informasi reward wajib diisi!')
        .test(
          'max-length',
          ({ value }) =>
            `Informasi reward melebihi batas maksimum! (${value?.length}/${MAX_LENGTH})`,
          value => !value || value.length <= MAX_LENGTH,
        ),
    }),
  campaignDetailTitle: Yup.string()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.string()
        .required('Judul informasi wajib diisi')
        .test(
          'max-length',
          ({ value }) =>
            `Judul informasi melebihi batas maksimum! (${value?.length}/${MAX_LENGTH_TITLE})`,
          value => !value || value.length <= MAX_LENGTH_TITLE,
        ),
    }),
  campaignDetailInformation: Yup.string()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.string()
        .required('Informasi campaign wajib diisi!')
        .test(
          'max-length',
          ({ value }) =>
            `Informasi campaign melebihi batas maksimum! (${value?.length}/${MAX_LENGTH_TEXT_AREA})`,
          value => !value || value.length <= MAX_LENGTH_TEXT_AREA,
        ),
    }),
  campaignDetailAdditionalInformation: Yup.string()
    .nullable()
    .when('isCampaign', {
      is: true,
      then: Yup.string().test(
        'max-length',
        ({ value }) =>
          `Informasi campaign melebihi batas maksimum! (${value?.length}/${MAX_LENGTH_TEXT_AREA})`,
        value => !value || value.length <= MAX_LENGTH_TEXT_AREA,
      ),
    }),
});

export const CAMPAIGN_DEFAULT_TOUCHED = {
  campaignName: true,
  campaignStartDate: true,
  campaignEndDate: true,
  campaignReward: true,
  selectedDropWaste: true,
  selectedMarker: true,
  wasteTypeCampaignRewardInformation: true,
  wasteStationCampaignRewardInformation: true,
  campaignDetailTitle: true,
  campaignDetailInformation: true,
};
